<template>
  <v-dialog v-model="dialogState" height="100%" persistent scrollable max-width="500px">
    <v-form ref="form" v-model="validForm" lazy-validation>
      <v-card>
        <!--        <v-card-title class="text-subtitle-1 grey lighten-2 text-uppercase d-flex justify-space-between">-->
        <v-card-title class="text-subtitle-1 grey lighten-2 text-uppercase d-flex justify-space-between">
          <div>Update Password</div>
          <v-btn icon @click="closeForm">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="mt-3 px-5 pt-2">
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                  :error-messages="getUserErrors.email"
                  dense
                  v-model="form.old_password"
                  label="Current Password"
                  :type="showpass ? 'text' : 'password'"
                  outlined
                  :rules="[getRules.required,validatePassword]"
                  :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showpass = !showpass"
                  autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-1">
              <v-text-field
                  dense
                  :error-messages="getUserErrors.email"
                  v-model="form.new_password"
                  label="New Password"
                  :type="showpass1 ? 'text' : 'password'"
                  outlined
                  :rules="[getRules.required,validateNewPassword]"
                  :append-icon="showpass1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showpass1 = !showpass1"
                  autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-1">
              <v-text-field
                  dense
                  v-model="form.confirm_password"
                  label="Confirm Password"
                  :type="showpass2 ? 'text' : 'password'"
                  outlined
                  :rules="[getRules.required,validateConfirmPassword]"
                  :append-icon="showpass2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showpass2 = !showpass2"
                  autocomplete="off"
              ></v-text-field>

            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="px-5 py-3 elevation-4">
          <v-btn block color="primary" :loading="loading" @click="validate">
            <span v-if="formType === 'add'">
              <v-icon class="mr-2">mdi-plus</v-icon>
            Add Member
            </span>
            <span v-else>
              <v-icon class="mr-2">mdi-pencil</v-icon>
            Update Password
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>

import {mapGetters, mapMutations, mapActions} from 'vuex';

export default {
  name: "ChangePassword",
  props: ['formType', 'dialogState'],
  data: () => ({
    validForm: true,
    loading: false,
    form: new Form(),
    showpass: false,
    showpass1: false,
    showpass2: false,
    successMsg:'',

  }),
  mounted() {
    this.initForm();
  },
  computed: {
    ...mapGetters(['getRules', 'getUser', 'getAuthUser','getChangePasswordErrors', 'getUserErrors' , 'getChangePasswordStatus'])
  },
  methods: {
    async initForm(state = 'add') {
      if (state === 'edit') this.form = new Form(this.getAuthUser);
      else this.form = new Form(this.getNewUser);
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.submit();
      }
    },
    validatePassword(){
      if(this.form.password && this.form.password.length < 8){
        return "The password must be at least 8 characters.";
      }
      return true;
    },
    validateNewPassword(){
      if(this.form.new_password === this.form.old_password){
        return "Please enter a password which is not similar to current password.";
      }
      else if(this.form.new_password.length < 6){
        return "Please enter the min 6 character password.";
      }
      return true;
    },
    validateConfirmPassword(){
      if(this.form.confirm_password !== this.form.new_password){
        return "Confirm Password Should be match with New Password";
      }
      return true;
    },
    async submit() {
      let user = Object.assign({}, this.form);
      this.loading = true;
      if (this.formType === 'edit') {

        await this.changePassword(user);

        this.successMsg = 'Password Updated Successfully';
      }
      this.loading = false;
      if (_.isEmpty(this.getChangePasswordErrors )){ this.showMessage(); this.closeForm(); }
      else{
        this.successMsg = 'Current Password is Mismatch';
        this.showMessage('error');
      }
    },
    showMessage(type='success'){
      this.$emit('message',this.successMsg,type);
    },
    closeForm() {
      this.resetUserErrors();
      this.resetNewUser();
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$emit('close-form', true);
    },

    ...mapMutations(['setUser', 'setNewUser','resetNewUser','resetUserErrors','setChangePasswordStatus']),
    ...mapActions(['addUser', 'updateUser' , 'changePassword'])
  }
}
</script>

<style scoped>

</style>