<template>
  <v-dialog v-model="dialogState" height="100%" persistent scrollable max-width="500px">
    <v-form ref="form" v-model="validForm" lazy-validation>
      <v-card>
        <!--        <v-card-title class="text-subtitle-1 grey lighten-2 text-uppercase d-flex justify-space-between">-->
        <v-card-title class=" text-subtitle-1 grey lighten-2 text-uppercase d-flex justify-space-between">
          <div v-if="formType === 'add'">New User</div>
          <div v-else>Edit User</div>
          <v-btn icon @click="closeForm">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="px-5 pt-2 mt-3">
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                dense
                v-model="form.first_name"
                label="First Name"
                outlined
                :rules="[getRules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-text-field
                dense
                v-model="form.last_name"
                label="Last Name"
                outlined
                :rules="[getRules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-text-field
                dense
                v-model="form.job_title"
                label="Job Title"
                outlined
                :rules="[getRules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-5" v-if="formType === 'add' && getAuthUser.user_type === 'admin'">
              <select-box
                v-model="form.account_id"
                :openMenu="false"
                item-value="id"
                :hintText="'Select Account'"
                :rules="[getRules.required]"
                :options="getAccounts"
                :selectedItemId="form.account_id"
                :selectedItemName="form.account_name"
                class="select"
              ></select-box>
            </v-col>
            <v-col cols="12" class="pb-5" v-if="formType === 'add' && form.account_id !== null">
              <select-box
                v-model="form.organization_id"
                :openMenu="false"
                item-value="id"
                :hintText="'Select Organization'"
                :rules="[getRules.required]"
                :options="organizations"
                :selectedItemId="form.organization_id"
                :selectedItemName="form.organization_name"
                class="select"
                @change="setVendorOrganization(form.organization_id)"
              ></select-box>
            </v-col>

            <v-col cols="12" class="py-1" v-if="getAuthUser.user_type === 'admin'">
              <v-select
                v-model="form.user_role"
                :items="rolesList"
                label="User Role"
                item-text="label"
                item-value="value"
                class="mt-0"
                :rules="[getRules.required]"
                outlined
                dense
              >
              </v-select>
            </v-col>

            <v-col cols="12" class="py-1">
              <v-text-field
                :error-messages="getUserErrors.email"
                dense
                v-model="form.email"
                label="Email"
                outlined
                :rules="[getRules.required, getRules.email]"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-1">
              <v-text-field
                dense
                v-model="form.phone"
                label="Phone"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-1" v-if="formType === 'add'">
              <v-text-field
                dense
                v-model="form.password"
                label="Password"
                :type="showPass ? 'text' : 'password'"
                outlined
                :rules="[getRules.required, validatePassword]"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPass = !showPass"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="px-5 py-3 elevation-4">
          <v-btn block color="primary" :loading="loading" @click="validate">
            <span v-if="formType === 'add'">
              <v-icon class="mr-2">mdi-plus</v-icon>
              Add Member
            </span>
            <span v-else>
              <v-icon class="mr-2">mdi-pencil</v-icon>
              Update Member
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
const SelectBox = () => import(/* webpackChunkName: "components" */ "@/components/SelectBox");

export default {
  name: "UserForm",
  components: { SelectBox },
  props: ["formType", "dialogState"],
  data: () => ({
    validForm: true,
    loading: false,
    form: new Form(),
    showPass: false,
    successMsg: "",
    rolesList: [
      { value: "master", label: "Master" },
      { value: "regular", label: "Regular" }
    ]
  }),
  mounted() {
    this.initForm();
  },
  computed: {
    organizations() {
      let accounts = this.getAccounts;
      if (this.form.account_id) {
        accounts = _.filter(accounts, { id: this.form.account_id });
      }

      let organizations = [];
      _.forEach(accounts, account => {
        _.forEach(account.organizations, organization => {
          organizations.push(organization);
        });
      });
      return organizations;
    },
    ...mapGetters(["getRules", "getNewUser", "getUser", "getAuthUser", "getUserErrors", "getAccounts"])
  },
  methods: {
    async initForm(state = "add") {
      if (state === "edit") this.form = new Form(this.getUser);
      else this.form = new Form(this.getNewUser);
    },

    reset() {},
    validate() {
      if (this.$refs.form.validate()) {
        this.submit();
      }
    },
    validatePassword() {
      if (this.form.password && this.form.password.length < 8) {
        return "The password must be at least 8 characters.";
      }
      return true;
    },
    async submit() {
      // this.form.organization_id = this.getAuthUser.organization_id;
      let user = Object.assign({}, this.form);
      this.loading = true;
      if (this.formType === "add") {
        if (this.form.organization_id === null) {
          user.organization_id = this.getAuthUser.organization_id;
        }

        if (!user.user_role) {
          user.user_role = "regular";
        }

        if (!user.user_type) {
          user.user_type = "seller";
        }

        user.status = "active";
        this.setNewUser(user);
        this.successMsg = "User added Successfully";
        await this.addUser();
      } else {
        this.setUser(user);
        this.successMsg = "User Updated Successfully";
        await this.updateUser();
      }
      this.loading = false;
      if (_.isEmpty(this.getUserErrors)) {
        this.showMessage();
        this.closeForm();
      } else {
        if (this.getUserErrors.email[0] === "The email has already been taken.") {
          this.successMsg = "The email has already been taken.";
          this.showMessage("error");
        } else {
          this.successMsg = "Error occur";
          this.showMessage("error");
        }
      }
    },
    showMessage(type = "success") {
      this.$emit("message", this.successMsg, type);
    },
    closeForm() {
      this.resetUserErrors();
      this.resetNewUser();
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$emit("close-form", true);
      this.form = new Form(this.getNewUser);
    },
    ...mapMutations(["setUser", "setNewUser", "resetNewUser", "resetUserErrors", "setVendorOrganization"]),
    ...mapActions(["addUser", "updateUser"])
  }
};
</script>
